// start content
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans:400,400i,700,700i|Source+Code+Pro");

/* === Core Styles === */



body {
    background-color: #fafafa;
    color: #4c5454;
    font-size: 15px;
    font-weight: 100;
    line-height: 1.6em;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", "Open Sans", serif;
    display: flex;
    flex-direction: column;
    * {
        max-height: 1000000em;
    }
}

h1,
h2,
h3,
h4,
p,
li,
dt,
dd {
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
}

h1,
.post-meta {
    text-align: center;
}

h2 {
    text-align: center;
    font-size: 24px;
}

a {
    color: #030404;
    text-decoration: none;
    transition: color ease 0.7s;
    -webkit-transition: color ease 0.7s;

    &:visited {
        color: #030404;
        text-decoration: none;
        transition: color ease 0.7s;
        -webkit-transition: color ease 0.7s;
    }

    &:focus,
    &:hover {
        color: #000000;
    }
}

p,
li,
dt,
dd {
    font-size: 18px;
    font-weight: 100;
    line-height: 30px;
}

dt {
    font-weight: 400;
}

li {
    padding-left: 0.8em;
}

pre {
    font-family: "Source Code Pro", "Noto Sans Bengali UI", monospace;
    font-size: 1em;
    line-height: 1.2em;
    padding: 1em;
    white-space: pre;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.05);
}

p code {
    font-family: "Source Code Pro", "Noto Sans Bengali UI", monospace;
    font-size: 0.8em;
    line-height: 1.2em;
}

h1 {
    color: #151515;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    margin: 0;
    padding: 0;
}

#site-head {
    margin: 20px auto;
    max-width: 600px;
    width: 90%;
}

article {
    margin: 20px auto;
    max-width: 600px;
    width: 90%;
    display: flex;
    flex-direction: column;

    &.preview {
        margin: 20px auto;
        max-width: 600px;
        width: 90%;
    }
}

a.archive-link,
.article-nav {
    margin: 20px auto;
    max-width: 600px;
    width: 90%;
}

#site-head img {
    max-width: 100%;
}

article.preview {
    margin-bottom: 4em;
    display: flex;
    flex-direction: column;
}

p.readmore {
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    margin-top: 1em;
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    text-align: center;
}


/* === Header === */

#blog-logo {
    display: block;
    max-width: 100px;
    margin: 50px auto 0;
    text-align: center;

    div.bloglogo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-size: cover !important;
        background-position: center center !important;
        background-color: #ffffff;
    }
}

h1.blog-title,
h2.blog-description {
    text-align: center;
}

h1.blog-title {
    font-size: 48px;
    line-height: 52px;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 0;
}

h2.blog-description {
    border-bottom: 1px solid #ecf0f1;
    border-top: 1px solid #ecf0f1;
    margin-bottom: 2em;
    padding: 0.5em 1em;
    line-height: 1.2em;
}

section.footer-description {
    margin-bottom: 1em;
}

header {

    &.article-header,
    &.page-header {
        margin-bottom: 3em;
    }
}

/* === Body === */

section {
    &.author {
        width: 100%;
        text-align: left;
        text-transform: none;
    }

    &.share {
        width: 100%;
        text-transform: none;
        margin-top: 1em;
        border-top: 1px solid #ecf0f1;
        padding-top: 1em;
        text-align: right;

        i {
            color: #202020;
        }
    }

    &.author {

        h4,
        p {
            padding: 0;
            margin: 0;
        }
    }

    &.share p {
        padding: 0;
        margin: 0;
    }

    &.author {
        h4 {
            font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
            font-weight: 400;
            margin-bottom: 4px;
        }

        p.attr {
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
        }
    }

    &.share p.info {
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;

        &.prompt {
            top: -5px;
            position: relative;
        }
    }

    &.author p.attr {
        margin-bottom: 2px;
    }

    &.share p.info {
        display: inline-block;
    }

    &.author p.bio {
        font-size: 16px;
        margin-left: 82px;
        line-height: 1.4em;
    }
}

blockquote {
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.2em;
    margin-left: 1em;
    padding-left: 1em;
    padding-bottom: 1em;

    @media (orientation: portrait) {
        padding-bottom: 1.2em;
    }

    p {
        font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
        font-size: 1.4em;
        font-weight: 300;
        line-height: 1.2em;
        font-style: italic;

        &::before {
            content: " ";
            font-family: "FontAwesome";
            font-size: 1.5em;
            vertical-align: text-top;
        }

        .author {
            color: black;
            float: right;
            font-size: 0.8em;
        }
    }
}

.post-meta {
    color: #95a5a6;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    font-size: 20px;
    font-weight: 300;
    margin-top: 1em;
    margin-bottom: 1.2em;
    text-transform: uppercase;
}

.tags {
    font-size: 16px;
    margin-bottom: 0.6em;
}

div.authorimage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-size: cover !important;
    background-position: center center !important;
    margin-right: 10px;
    float: left;
}

h1 {

    &.post-title a,
    &.blog-title a {
        color: #151515;
        transition: color ease 0.7s;
        -webkit-transition: color ease 0.7s;
    }

    &.post-title a {

        &:focus,
        &:hover {
            color: #030404;
            text-decoration: none;
        }
    }

    &.blog-title a {

        &:focus,
        &:hover {
            color: #030404;
            text-decoration: none;
        }
    }
}

h2 {
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    margin-top: 2em;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    font-size: 24px;
}

section {
    &.post-content img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }

    &.preview.archive h2 {
        text-align: left;
    }

    &.author {
        overflow: hidden;
    }
}

img.userimage {
    width: 70px;
    max-width: 70px;
    max-height: 70px;
    border-radius: 50%;
    margin-right: 10px;
    float: left;
}

.post-content p img,
img.iml {
    float: left;
    padding-right: 15px;
    width: 60%;
    height: auto;
}

.post-content p img.imr {
    float: right;
    padding-left: 15px;
    width: 60%;
    height: auto;
}

.post-content p img.imc {
    float: right;
    margin-bottom: 15px;
    width: 100%;
    height: auto;
}

.post-content p img,
img.iml,
img.imr {
     @media screen and (max-width: 415px) {
         width: 100%;
         height: auto;
         max-height: unset;
         padding-right: 0px;
         padding-left: 0px;
         margin-bottom: 15px;
     }
}

.post-excerpt p img,
img.iml {
    float: left;
    padding-right: 15px;
    width: 60%;
    height: auto;
}

.post-excerpt p img.imr {
    float: right;
    padding-left: 15px;
    height: auto;
    width: 60%;
}

.post-excerpt p img.imc {
    float: right;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.post-excerpt p img,
img.iml,
img.imr {
    @media screen and (max-width: 415px) {
        width: 100%;
        height: auto;
        max-height: unset;
        padding-right: 0px;
        padding-left: 0px;
        margin-bottom: 15px;
    }
}

.article-footer {
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .social-icons-post {
        display: flex;
        justify-content: flex-end;

        .social-icon {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

        a {
            text-decoration: none !important;
            width: 40px;
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 25px;
            overflow: hidden;
            position: relative;
            color: #4c5454;
            -webkit-transition: all 0.2s linear 0.2s;
            transition: all 0.2s linear 0.2s;

            i {
                position: relative;
                z-index: 3;
                font-size: 22px;
                display: inline-block;
                vertical-align: middle;
                -webkit-transition: all 0.2s linear 0s;
                transition: all 0.2s linear 0s;
            }

            &:last-child {
                margin-right: 0px;
            }

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            &:hover {
                -webkit-transition: all 0.2s linear 0s;
                transition: all 0.2s linear 0s;
                border-color: rgba(33, 33, 33, 0);

                i {
                    -webkit-transform: scale(1.15);
                    transform: scale(1.15);
                    -webkit-transition: all 0.2s linear 0.2s;
                    transition: all 0.2s linear 0.2s;
                }
            }
        }
    }

    .meta {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .post-meta-bottom {
            width: 100%;
            text-align: right;
        }
    }
}

/* === Footer === */

footer {
    background: rgba(0, 0, 0, 0.05);
    margin: 4em 0 0;
    padding: 2em 0;
    text-align: center;

    section {
        font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
        font-weight: 300;
        font-size: 18px;
        margin: 0 auto;
        max-width: 600px;
        text-transform: uppercase;
        width: 90%;
    }

    &.post-footer {
        background: none;
        border-top: 1px solid #ecf0f1;
        margin-top: 1em;
    }

    @media screen and (min-width: 1280px){
        width: 40vw;
        max-width: 600px;
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
    }

}

/* === Navigation and Pagination === */

nav {
    border-bottom: 1px solid #ecf0f1;
    border-top: 1px solid #ecf0f1;
    margin-bottom: 2em;
    text-align: center;
    margin: 20px auto 2em auto;
    max-width: 600px;

    ul {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        overflow: hidden;
        text-align: center;

        li {
            display: inline-block;
            font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
            font-size: 18px;
            font-weight: 300;
            margin: 0;
            padding: 0 0.5em;
            text-align: center;
            text-transform: uppercase;

            a {
                display: block;
                padding: 0.5em 0;
            }
        }
    }

    &.pagination li {
        &:first-child {
            margin-left: 0;
            margin-right: 0;
            text-align: right;
        }

        &:last-child {
            margin-left: 0;
            margin-right: 0;
            text-align: left;
        }
    }
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0px;
    font-size: 1.6em;

    div {
        margin: 0px 10px;
    }
}


@media only screen and (min-width: 320px) and (max-width: 662px) {
    nav.menu li {
        display: block;
    }
}

a.archive-link {
    border-bottom: 1px solid #ecf0f1;
    border-top: 1px solid #ecf0f1;
    display: block;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 3em;
    margin-top: 3em;
    padding: 0.5em 0;
    text-align: center;
    text-transform: uppercase;
}

.pagination {
    position: relative;
    width: 90%;
    max-width: 600px;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    text-transform: uppercase;
    font-size: 20px;
    color: #4c5454;
    text-align: center;
    padding: 0.5em 0;

    a {
        color: #030404;
    }
}

.older-posts,
.newer-posts {
    position: absolute;
    display: inline-block;
    padding: 0;
    text-decoration: none;
    transition: color ease 0.7s;
    -webkit-transition: color ease 0.7s;
}

.older-posts {
    right: 0;
}

.page-number {
    display: inline-block;
    padding: 0;
    margin: 0;
}

.newer-posts {
    left: 0;
}

.older-posts:hover,
.newer-posts:hover {
    color: #000000;
    text-decoration: none;
}

/* === Extras === */

.gist {
    margin-top: 1em;
    margin-bottom: 1em;
    border-collapse: collapse !important;

    .gist-file {
        font-family: "Source Code Pro", "Noto Sans Bengali UI", monospace;
        border: 1px solid rgba(0, 0, 0, 0.05) !important;

        .gist-meta {
            font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
            font-weight: 300 !important;
            text-transform: uppercase !important;
            display: none !important;

            /* Hide GitHub attribution */
            a:visited {
                color: #030404 !important;
            }
        }

        .gist-data {
            background: #fdfdfd !important;
            border-bottom: none !important;

            /* Hide GitHub attribution */
            .line-numbers {
                border-right: none !important;
                background-color: rgba(0, 0, 0, 0.05) !important;
            }
        }

        .gist-meta {
            background-color: rgba(0, 0, 0, 0.05) !important;
        }

        .gist-data {

            .line-numbers,
            .line-pre {
                line-height: 1.2em !important;
            }
        }
    }
}

.hidden {
    text-indent: -9999px;
    visibility: hidden;
    display: none;
}

@font-face {
    font-family: "icons";
    src: url("../fonts/icons.eot");
    src: url("../fonts/icons.eot?#iefix") format("embedded-opentype"), url("../fonts/icons.woff") format("woff"), url("../fonts/icons.ttf") format("truetype"), url("../fonts/icons.svg#icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.icon-ghost,
.icon-feed {
    font-family: "icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}

.icon-ghost:before {
    content: "";
}

.icon-feed:before {
    content: "";
}

.icon-ghost {
    font-size: 12px;
    position: relative;
    top: -1px;
}

/* Comments */

#disqus_thread {
    color: #787878;
}

/* Tables */

table {
    width: 100%;
    font-family: "Noto Sans Bengali UI", "Merriweather Sans", serif;
    border-collapse: collapse;
    margin: 1em 0 3em;

    td {
        padding: 0.5em;
    }

    thead {
        font-weight: 600;
        border-bottom: 1px solid #ecf0f1;
    }

    tbody tr:nth-child(even) {
        background-color: #ecf0f1;
        border-top: 1px solid #e3e9eb;
        border-bottom: 1px solid #e3e9eb;
    }

    tfoot {
        border-top: 1px solid #ecf0f1;
    }
}

/* === Media Queries === */
@media only screen and (min-width: 320px) and (max-width: 500px) {
    footer section span.copyright {
        display: block;
    }

    nav li {
        width: auto;
    }

    .older-posts {
        position: static;
    }

    .newer-posts {
        position: static;
        margin-bottom: 10px;
    }

    .older-posts {
        margin-top: 10px;
    }

    .page-number {
        display: block;
    }
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #000000;
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    i {
        color: #fff;
        margin: 0;
        position: relative;
        left: 16px;
        top: 13px;
        font-size: 19px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.9);

        i {
            color: #fff;
            top: 5px;
        }
    }
}

.tag-cloud {
    list-style: none;
    padding: 0;
    text-align: justify;
    font-size: 16px;

    li {
        display: inline-block;
        margin: 0 12px 12px 0;
    }
}

#archives {
    padding: 5px;
}

.archive-group {
    margin: 5px;
    border-top: 1px solid #ddd;

    h3 {
        font-size: 2rem;
    }
}

.archive-item {
    margin: 5px;

    h4 {
        margin: 0px;
    }
}

.post-tags {
    text-align: right;
}

.sticky {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    i {
        font-size: 40px !important;
    }
}

.fnote {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    display: none;

    .note-wrapper {
        margin: 20px auto;
        max-width: 600px;
        width: 90%;
        max-height: 30vh;
        overflow: auto;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #E0E0E0;
        -webkit-box-shadow: 2px 2px 5px 0px #f1f1f1;
        -moz-box-shadow: 2px 2px 5px 0px #f1f1f1;
        box-shadow: 2px 2px 5px 0px #f1f1f1;
    }
}

.shownote {
    display: flex;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;

    &:hover {
        background: #ffffff;
    }

    &:active {
        background: #000000;
    }
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;

    &:hover {
        background: #666666;
    }

    &:active {
        background: #333333;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}

.invisible {
    display: none;
}

form#search {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 40px;

    #search-box {
        flex: 1;
        border: none;
        border-bottom: 1px solid #000000;
        padding-left: 10px;
        outline: none;
    }

    .search-btn {
        border: none;
        background: #fdfdfd;
        border-bottom: 1px solid #000000;
        width: 20px;
    }
}

#search-results {
    margin: 10px;
    font-size: 1.3em;
}

.emoji {
    display: initial !important;
    float: initial !important;
    padding-right: 0;
}



.social-icon:hover a {
    opacity: 0.5;
}

.social-icon:hover a:hover {
    opacity: 1;
}

a:hover {
    color: gray !important;
}

.opolap {
    font-style: italic;
    font-size: 1.2rem;
    border-top: 1px solid #202020;
    margin-top: 20px;
    padding-top: 10px;
}

.supreme-container {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1280px){
        flex-direction: row;
         #site-head {
            width: 40vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            margin: 0 0;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: rgba(0,0,0,0);
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            .blog-description {
                margin: 0;
                border: none;
            }

            .menu {
                border: none;
            }
         }

         .content {
             flex: 1;
             padding-top: 30px;
             height: 100vh;
             overflow-y: scroll;
             box-sizing: border-box;
         }

    }

    // #site-head {
    //     @media screen and (min-width: 1280px){
    //         max-width: 40vw;
    //         height: 100vh;
    //     }
    // }
}


.gsc-search-box table {
    margin-bottom: 0;
    margin-top: 0;
}

.gsc-results {
    background-color: #fafafa;
    width: auto;
    font-weight: normal;
}

button.gsc-search-button {
    max-height: unset;
    svg {
        max-height: unset;
    }
}

.pointer {
    cursor: pointer;
}

.footnotes {
    ol {
        list-style-type: bengali;
    }
}

.fnote {
    z-index: 999;
    .reversefootnote {
        display: none;
    }
}

.fnote-marker {
    font-size: 90%;
    font-weight: bold;
}